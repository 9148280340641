import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../utils/axios';
//
import { dispatch } from './store';

const initialState = {
  isLoading: false,
  isLoadingUpload: false,
  error: null,
  successUpload: false,
  isLoadingMap: false,
  categories: {
    name: '',
    id: '',
    children_categories: [],
  },
  childrens: {
    name: '',
    id: '',
    children_categories: [],
  },
  attributes: [],
  countries: [],
  cities: [],
  location: {
    latitude: 0,
    longitude: 0,
  },
  zoom: 4,
  neighborhoods: {},
  coordenates: {
    latitude: 0,
    longitude: 0,
  },
  urlImages: [],
  profileMeli: {},
  currencies: [],
  newProperty: {
    id_property: '',
  },
};

const slice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    // RESTORE STATE
    restoreState: (state) => {
      state.isLoading = false;
      state.error = null;
      state.successUpload = false;
      state.categories = {
        name: '',
        id: '',
        children_categories: [],
      };
      state.childrens = {
        name: '',
        id: '',
        children_categories: [],
      };
      state.attributes = [];
      state.countries = [];
      state.cities = [];
      state.location = {
        latitude: 0,
        longitude: 0,
      };
      state.zoom = 4;
      state.neighborhoods = {};
      state.coordenates = {
        latitude: 0,
        longitude: 0,
      };
      state.urlImages = [];
      state.profileMeli = {};
      state.currencies = [];
      state.newProperty = {};
    },
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    startUploadLoading(state) {
      state.isLoadingUpload = true;
    },
    startLoadingMap(state) {
      state.isLoadingMap = true;
      state.error = null;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.isLoadingMap = false;
      state.error = action.payload;
    },
    // GET CATEGORIES
    getCateogries(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
    },
    getChildrensCategories(state, action) {
      state.isLoading = false;
      state.childrens = action.payload;
    },
    getAttributes(state, action) {
      state.isLoading = false;
      state.attributes = action.payload;
    },
    getCountries(state, action) {
      state.isLoading = false;
      state.countries = action.payload.states;
      state.location = action.payload.geo_information.location;
    },
    getCities(state, action) {
      state.isLoading = false;
      state.cities = action.payload.cities;
      state.location = action.payload.geo_information.location;
    },
    getZoom(state, action) {
      state.isLoading = false;
      state.zoom = action.payload;
    },
    getNeighborhoods(state, action) {
      state.isLoading = false;
      state.neighborhoods = action.payload;
      state.location = action.payload.geo_information.location;
    },
    getCoordenates(state, action) {
      state.isLoadingMap = false;
      state.coordenates = action.payload.geo_information.location;
      state.location = action.payload.geo_information.location;
    },
    getUrlImages(state, action) {
      state.isLoadingUpload = false;
      state.urlImages = action.payload;
      state.successUpload = true;
    },
    getProfileMeli(state, action) {
      state.isLoading = false;
      state.profileMeli = action.payload;
    },
    getCurrencies(state, action) {
      state.isLoading = false;
      state.currencies = action.payload;
    },
    getProperty(state, action) {
      state.isLoading = false;
      state.newProperty = {
        id_property: action.payload.id_property,
      };
    },
    removeChildrens(state) {
      state.childrens = {
        name: '',
        id: '',
        children_categories: [],
      };
    },
  },
});

// Reducer
export default slice.reducer;

export function getCategories() {
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.restoreState());
    try {
      const response = await axios.get('/api/account/get-categories');
      dispatch(slice.actions.getCateogries(response.data.categories));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getChildrensCategories(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/account/get-childrens-categories/${id}`);
      dispatch(slice.actions.getChildrensCategories(response.data.childrens));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAttributes(category) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/account/get-attributes/${category}`);
      dispatch(slice.actions.getAttributes(response.data.attributes));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCountries() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/account/get-countries`);
      dispatch(slice.actions.getCountries(response.data.countries));
      dispatch(slice.actions.getZoom(response.data.zoom));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCities(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/account/get-cities/${id}`);
      dispatch(slice.actions.getCities(response.data.cities));
      dispatch(slice.actions.getZoom(response.data.zoom));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNeighborhoods(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/account/get-neighborhoods/${id}`);
      dispatch(slice.actions.getNeighborhoods(response.data.neighborhoods));
      dispatch(slice.actions.getZoom(response.data.zoom));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function searchAddress(address, city) {
  return async () => {
    dispatch(slice.actions.startLoadingMap());

    try {
      const response = await axios.get(`/api/google/get-address?address=${address}&city=${city}`);
      dispatch(slice.actions.getCoordenates(response.data.address));
      dispatch(slice.actions.getZoom(response.data.zoom));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function uploadClientImage(images) {
  return async () => {
    dispatch(slice.actions.startUploadLoading());
    try {
      const response = await axios.post(`/api/property/upload-multiple-image`, images);
      dispatch(slice.actions.getUrlImages(response.data.urlImages));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProfileMeli() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/meli/me`);
      dispatch(slice.actions.getProfileMeli(response.data.profil));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCurrencies() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/mercadolibre/get-currencies`);
      dispatch(slice.actions.getCurrencies(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function resetAttributes() {
  return async () => {
    dispatch(slice.actions.removeChildrens());
  };
}
export function removeElements() {
  return async () => {
    await dispatch(slice.actions.restoreState());
  };
}
