import _mock from './_mock';
import { randomInArray } from './funcs';

// ----------------------------------------------------------------------

export const _carouselsExample = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  title: _mock.text.title(index),
  image: _mock.image.feed(index),
  description: _mock.text.description(index),
}));

export const _carouselsMembers = [
  {
    id: 0,
    name:"Matias Suárez",
    role: "CEO & Co-founder",
    avatar: `https://ca.slack-edge.com/T02GDKM3CCB-U02GAL8EG05-f2cffcb849c3-192`,
  },
  {
    id: 1,
    name:"Gustavo Aceredo",
    role: "CTO & Co-founder",
    avatar: `https://media-exp1.licdn.com/dms/image/C4E03AQEQ-gd5D7jyAw/profile-displayphoto-shrink_400_400/0/1583464162111?e=1654128000&v=beta&t=64FsdMIGhBWm9SVSlURn5YkQVtW9t8eArRqN7yXf-Y0`,
  },{
  id: 2,
  name:"Santiago Ferreira",
  role: "COO & Co-founder",
  avatar: `https://ca.slack-edge.com/THZ0ADJ3C-UJ9S0ALRJ-4e5f1bce6e72-192`,
},{
  id: 3,
  name:"Nicolas Vargas",
  role: "COO & Co-founder",
  avatar: `https://ca.slack-edge.com/T02GDKM3CCB-U02GFQRSG3E-baf093b2fa39-192`,
}]
// ----------------------------------------------------------------------

export const _invoice = {
  id: `${Date.now()}`,
  taxes: 5,
  discount: 10,
  status: 'paid',
  invoiceFrom: {
    name: _mock.name.fullName(1),
    address: _mock.address.fullAddress(1),
    company: _mock.company(1),
    email: _mock.email(1),
    phone: _mock.phoneNumber(1),
  },
  invoiceTo: {
    name: _mock.name.fullName(2),
    address: _mock.address.fullAddress(2),
    company: _mock.company(2),
    email: _mock.email(2),
    phone: _mock.phoneNumber(2),
  },
  items: [...Array(3)].map((_, index) => ({
    id: _mock.id(index),
    title: _mock.text.title(index),
    description: _mock.text.description(index),
    qty: 5,
    price: _mock.number.price(index),
  })),
};

// ----------------------------------------------------------------------

export const _faqs = [...Array(8)].map((_, index) => ({
  id: _mock.id(index),
  value: `panel${index + 1}`,
  heading: `Pregunta ${index + 1}`,
  detail: _mock.text.description(index),
}));

// ----------------------------------------------------------------------

export const _addressBooks = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  receiver: _mock.name.fullName(index),
  fullAddress: _mock.address.fullAddress(index),
  phone: _mock.phoneNumber(index),
  addressType: index === 0 ? 'Home' : 'Office',
  isDefault: index === 0,
}));

// ----------------------------------------------------------------------

export const _skills = [...Array(3)].map((_, index) => ({
  label: ['SEO', 'Diseño', 'Usabilidad'][index],
  value: _mock.number.percent(index),
}));

// ----------------------------------------------------------------------

export const _accordions = [...Array(4)].map((_, index) => ({
  id: _mock.id(index),
  value: `panel${index + 1}`,
  heading: `Accordion ${index + 1}`,
  subHeading: _mock.text.title(index),
  detail: _mock.text.description(index),
}));

// ----------------------------------------------------------------------

export const _dataGrid = [...Array(36)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index),
  email: _mock.email(index),
  lastLogin: _mock.time(index),
  performance: _mock.number.percent(index),
  rating: _mock.number.rating(index),
  status: randomInArray(['online', 'away', 'busy']),
  isAdmin: _mock.boolean(index),
  lastName: _mock.name.lastName(index),
  firstName: _mock.name.firstName(index),
  age: _mock.number.age(index),
}));

// ----------------------------------------------------------------------

export const _megaMenuProducts = [...Array(10)].map((_, index) => ({
  name: _mock.text.title(index),
  image: _mock.image.feed(index),
  path: '#',
}));

// ----------------------------------------------------------------------

export const _contacts = [...Array(20)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index),
  username: _mock.name.fullName(index),
  avatar: _mock.image.avatar(index),
  address: _mock.address.fullAddress(index),
  phone: _mock.phoneNumber(index),
  email: _mock.email(index),
  lastActivity: _mock.time(index),
  status: randomInArray(['online', 'offline', 'away', 'busy']),
  position: _mock.role(index),
}));

// ----------------------------------------------------------------------

export const _notifications = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  title: ['Nuevo mail de Demo', 'Nuevo correo de Demo', 'You have new message', 'You have new mail', 'Delivery processing'][
    index
  ],
  description: [
    'nueva venta de apartamento',
    'nuevo alquiler de una casa',
    '5 unread messages',
    'sent from Guido Padberg',
    'Your order is being shipped',
  ][index],
  avatar: [null, _mock.image.avatar(2), null, null, null][index],
  type: ['order_placed', 'friend_interactive', 'chat_message', 'mail', 'order_shipped'][index],
  createdAt: _mock.time(index),
  isUnRead: [true, true, false, false, false][index],
}));

// ----------------------------------------------------------------------

export const _mapContact = [
  {
    latlng: [-34.9040845, -56.2035332],
    address: _mock.address.fullAddress(1),
    phoneNumber: _mock.phoneNumber(1),
  }
];
