import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
/* eslint-disable */
// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  properties: {
    forRent: 0,
    forSales: 0,
    clients: 0
  }
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  ACCESSTOKENML: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  SOCIALLINKS: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  UPLOADAGENTIMAGE: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  UPDATEAGENTPROFILE: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  UPDATEAGENTPASSWORD: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  PROPERTIESFORRENTANDSALES : (state, action) => {
    const { properties } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      properties : properties,
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  getAccessTokenML: () => Promise.resolve(),
  updateSocialLinks: () => Promise.resolve(),
  uploadAgentImage: () => Promise.resolve(),
  updateAgentProfile: () => Promise.resolve(),
  updatePassword: () => Promise.resolve(),
  getPropertiesForRentAndSales: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get('/api/account/my-account');
          const { user } = response.data;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const response = await axios.post('/api/account/login', {
      email,
      password,
    });
    
    const { accessToken, user } = response.data;

    setSession(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const register = async (email, password, firstName, lastName, country) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
      country
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const getAccessTokenML = async (code) => {
    const response = await axios.get(`/api/mercadolibre/get-access-token/${code}`);
    const user = response.data.user;
    dispatch({
      type: 'ACCESSTOKENML',
      payload: {
        user,
      },
    });
  };

  const getPropertiesForRentAndSales = async () => {
    const response = await axios.get(`/api/account/get-properties-for-rent-and-sales`);
    const properties = response.data.properties;
    dispatch({
      type: 'PROPERTIESFORRENTANDSALES',
      payload: {
        properties,
      },
    });
  };

  const updateSocialLinks = async (facebook, instagram, linkedin, twitter) => {
    const response = await axios.post(`/api/account/social-links`,{facebook, instagram, linkedin, twitter});
    const user = response.data.user;
    dispatch({
      type: 'SOCIALLINKS',
      payload: {
        user,
      },
    });
  };

  const uploadAgentImage = async (body) => {
    
    const response = await axios.post(`/api/account/upload-aget-profile-image`,body);
    const user = response.data.user;
    dispatch({
      type: 'UPLOADAGENTIMAGE',
      payload: {
        user,
      },
    });
  };

  const updateAgentProfile = async (body) => {
    const response = await axios.post(`/api/account/update-agent-profile`, body);
    const user = response.data.user;
    dispatch({
      type: 'UPDATEAGENTPROFILE',
      payload: {
        user,
      },
    });
  };

  const updatePassword = async (body) => {
    const response = await axios.post(`/api/account/update-password`, body);
    const user = response.data.user;
    dispatch({
      type: 'UPDATEAGENTPASSWORD',
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        getAccessTokenML,
        updateSocialLinks,
        uploadAgentImage,
        updateAgentProfile,
        updatePassword,
        getPropertiesForRentAndSales
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
