// routes
/*eslint-disable */

import { PATH_AUTH, PATH_PAGE, PATH_DASHBOARD } from '../../routes/paths';
// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------
// Es aqui
const ICON_SIZE = {
  width: 22,
  height: 22,
};

export const menuLogout = [
  {
    title: 'Inicio',
    icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    path: '/',
  },
  {
    title: 'FAQs',
    icon: <Iconify icon={'eva:book-open-outline'} {...ICON_SIZE} />,
    path: PATH_PAGE.faqs,
  },
  {
    title: 'Quienes Somos',
    icon: <Iconify icon={'eva:people-outline'} {...ICON_SIZE} />,
    path: PATH_PAGE.about,
  },
  {
    title: 'Precios',
    icon: <Iconify icon={'eva:pricetags-outline'} {...ICON_SIZE} />,
    path: PATH_PAGE.pricing,
  },
  {
    title: 'Contáctanos',
    icon: <Iconify icon={'eva:log-in-outline'} {...ICON_SIZE} />,
    path: PATH_PAGE.contact,
  },
  {
    title: 'Iniciar Sesión',
    icon: <Iconify icon={'eva:book-open-fill'} {...ICON_SIZE} />,
    path: PATH_AUTH.login ,
  },
]
export const menuLogin = [
  {
    title: 'Inicio',
    icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    path: '/',
  },
  {
    title: 'FAQs',
    icon: <Iconify icon={'eva:book-open-outline'} {...ICON_SIZE} />,
    path: PATH_PAGE.faqs,
  },
  {
    title: 'Quienes Somos',
    icon: <Iconify icon={'eva:people-outline'} {...ICON_SIZE} />,
    path: PATH_PAGE.about,
  },
  {
    title: 'Precios',
    icon: <Iconify icon={'eva:pricetags-outline'} {...ICON_SIZE} />,
    path: PATH_PAGE.pricing,
  },
  {
    title: 'Contáctanos',
    icon: <Iconify icon={'eva:log-in-outline'} {...ICON_SIZE} />,
    path: PATH_PAGE.contact,
  }
]
