import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../utils/axios';
//
import { dispatch } from './store';

const initialState = {
  isLoading: false,
  error: null,
  clients: [],
  client: {},
  url: '',
  properties: []
};

const slice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET CLIENTS
    getListClients(state, action) {
      state.isLoading = false;
      state.clients = action.payload;
    },
    // GET CLIENT EDIT
    editClient(state, action) {
        state.isLoading = false;
        state.client = action.payload;
    },
    // CREATE CLIENT
    createClient(state, action) {
      state.isLoading = false;
      state.clients.push(action.payload);
    },
    // UPDATE CLIENT
    uploadImage(state, action) {
      state.isLoading = false;
      state.url = action.payload;
    },
    deleteClient(state, action) {
      state.isLoading = false;
      state.clients = state.clients.filter(client => client.id !== action.payload);
    },
    // GET PROPERTIES
    getDashboard(state, action) {
      state.isLoading = false;
      state.properties = action.payload.properties;
      state.clients = action.payload.clients;
    }
  },
});

// Reducer
export default slice.reducer;


export function getListClients() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get(`/api/client/get-list-client`);
        dispatch(slice.actions.getListClients(response.data.clients));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
}

export function editClient(body,id) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post(`/api/client/edit-client/${id}`, body);
        dispatch(slice.actions.editClient(response.data.client));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
}

export function createClient(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post(`/api/client/create-client`, data);
        dispatch(slice.actions.createClient(response.data.client));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
}

export function uploadClientImage(data) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post(`/api/client/upload-client-image`, data);
        dispatch(slice.actions.uploadImage(response.data.urlImage));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
}

export function deleteClient(id) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        await axios.get(`/api/client/delete-client/${id}`);
        dispatch(slice.actions.deleteClient(id));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
}
export function getDashboard() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/app/get-dashboard`);
      dispatch(slice.actions.getDashboard(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}