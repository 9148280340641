// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  invoice: getIcon('ic_invoice'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  blog: getIcon('ic_blog'),
};

const navConfig = ({id}) => [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [{ title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard }],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Administración',
    items: [
      // MANAGEMENT : USER
      {
        title: 'Clientes',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'lista', path: PATH_DASHBOARD.user.list },
          { title: 'nuevo', path: PATH_DASHBOARD.user.newUser },
        ],
      },

      // MANAGEMENT : E-COMMERCE
      {
        title: 'Inmuebles',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: ICONS.cart,
        children: [
          { title: 'lista', path: PATH_DASHBOARD.eCommerce.shop },
          { title: 'nuevo', path: PATH_DASHBOARD.eCommerce.newProduct },
        ],
      },
      // BLOG
      {
        title: 'Portal',
        path: PATH_DASHBOARD.blog.root,
        icon: ICONS.blog,
        children: [
          { title: 'Ir al Portal', path: PATH_DASHBOARD.blog.root },
          { title: 'Perfil', path: PATH_DASHBOARD.blog.view(id) },
        ],
      },
      // INVOICE
      {
        title: 'Facturación',
        path: PATH_DASHBOARD.invoice.root,
        icon: ICONS.invoice,
        children: [
          { title: 'lista', path: PATH_DASHBOARD.invoice.list },
          { title: 'nueva', path: PATH_DASHBOARD.invoice.new },
        ],
      },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'Aplicación',
    items: [
      // {
      //   title: 'Correo',
      //   path: PATH_DASHBOARD.mail.root,
      //   icon: ICONS.mail,
      //   info: (
      //     <Label variant="outlined" color="info">
      //       +2
      //     </Label>
      //   ),
      // },
      // {
      //   title: 'Chat',
      //   path: PATH_DASHBOARD.chat.root,
      //   icon: ICONS.chat,
      //   info: (
      //     <Label variant="outlined" color="success">
      //       +6
      //     </Label>
      //   ),
      // },
      { title: 'Calendario', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
      {
        title: 'Notas',
        path: PATH_DASHBOARD.kanban,
        icon: ICONS.kanban,
      },
    ],
  },
];

export default navConfig;
